import { TechnicalError } from '@orus.eu/error'
import { isSuccess } from '@orus.eu/result'
import * as Sentry from '@sentry/react'
import { init } from '@sentry/react'
import { router } from '../router'
import { getEnvironmentName, isReviewOrProductionEnvironment } from './env-utils'
import { logger } from './logger'
import { sessionManager } from './session'

export function ensureSentryIsInitialized(): void {
  if (!sentryIsInitialized) {
    initSentry()
    sentryIsInitialized = true
  }
}
let sentryIsInitialized = false

/**
 * Called one time at most by ensureSentryIsInitialized
 * - Starts uncaught error capture with sentry
 * - Watches error logs to send them to sentry
 */
function initSentry(): void {
  Sentry.setContext('Orus Session', {
    status: 'Not yet loaded, or failed to load',
  })

  sessionManager.onSessionRefreshed((sessionResult) => {
    if (isSuccess(sessionResult)) {
      const session = sessionResult.output
      Sentry.setContext('Orus Session', {
        id: session.id,
        user: session.user,
        troubleshooting: `${document.location.origin}/bak/troubleshooting/session/${session.id}`,
      })
    }
  })

  init({
    dsn: 'https://660b731ad79d48e2933a0d8c12e3018c@o1191729.ingest.sentry.io/6315883',
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.tanstackRouterBrowserTracingIntegration(router),
    ],
    enableTracing: false,
    environment: getEnvironmentName(),
    release: __CODE_VERSION_ID__,
    beforeSend,
    replaysSessionSampleRate: 0.1, // Sample rate of 10% of sessions
    replaysOnErrorSampleRate: 1.0, // Sample rate of 100% of sessions with errors
  })
  logger.addTransport({
    error(error) {
      reportToSentry(error)
    },
    event(_event, _metadata) {
      // not handling simple events
    },
  })
}

function beforeSend(event: Sentry.ErrorEvent, hint: Sentry.EventHint): Sentry.ErrorEvent | null {
  // Load sentry only on production and review environments. We don't want end to end tests and local dev
  // environments to create noise on sentry but review environments errors can serve as an early warning.
  if (!isReviewOrProductionEnvironment()) {
    console.info('In production or review environment, we would have sent this event to sentry', event)
    return null
  }

  const debugData = {
    eventException: event.exception,
    eventMessage: event.message,
    eventType: event.type,
    hintOriginalException: hint.originalException,
    hintSyntheticException: hint.syntheticException,
  }
  event.breadcrumbs = event.breadcrumbs || []
  event.breadcrumbs.push({ data: debugData })
  const { originalException } = hint

  return originalException instanceof TechnicalError
    ? // send technical errors to sentry
      event
    : // Ignore all other frontend errors and non-error objects, so that we don't
      // create alert-fatigue with all the noise created by browsers extensions and scripts injected through GTM,
      // for which we don't have possible actions most of the time
      null
}

function reportToSentry(error: Error): void {
  if (isReviewOrProductionEnvironment()) {
    ensureSentryIsInitialized()
    Sentry.withScope((scope) => {
      scope.setFingerprint(getErrorMessages(error))
      Sentry.captureException(error)
    })
  }
}

/**
 * Traverse and get all messages of an error and its causes.
 *
 * @param error The error to traverse
 *
 * @returns An array of error messages with all causes
 */
function getErrorMessages(error: Error) {
  const errorMessages: string[] = []

  let currentError: Error | undefined = error
  while (currentError) {
    errorMessages.push(currentError.toString())
    currentError = currentError.cause instanceof Error ? currentError.cause : undefined
  }

  return errorMessages
}
